import React from 'react';
import './App.scss';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';

import LandingPage from './pages/LandingPage';
import Footer from './components/Footer';
import HelmetHead from './components/HelmetHead';

import Logo from './assets/svg/logo.svg';

function App() {
  return (
    <div className="App">
      <HelmetHead />
      <Navbar bg="dark" variant="dark" expand="lg">
        <Navbar.Brand href="#">
          <img alt="codebetter logo" src={Logo} width="30" height="30"/>
          CodeBetter
        </Navbar.Brand>
        <Nav className="mr-auto">
        </Nav>
        <Button variant="light">Sign Up</Button>
      </Navbar>
      <LandingPage></LandingPage>
      <Footer />
    </div >
  );
}

export default App;
