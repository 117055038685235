export default [
    {
        id: 'header',
        title: 'Code better with the help of a mentor',
        subtitle: 'Schedule feedback sessions with senior devs. Free during lockdown.',
        img_urls: [require('../../assets/images/pair-programming.png')],
        img_alts: ['pair programming with laptop'],
    },
    {
        id: 'lost_job',
        title: 'Keep your skills sharp between jobs',
        subtitle: 'If you lost your job due to COVID-19,\nfind remote mentors to help you stay on top of your game.',
        img_urls: [require('../../assets/images/coding-programmer.png')],
        img_alts: ['programmer coding at desk'],
    },
    {
        id: 'best_practices',
        title: 'Stay on top of best practices',
        subtitle: 'Don’t have a manager or colleague to review your code?\nNo problem! Your mentor can help with that.',
        img_urls: [require('../../assets/images/coding-programmer-virtual.png')],
        img_alts: ['programmer coding on virtual screens'],
    },
    {
        id: 'lost_network',
        title: 'Don\'t let go of your network',
        subtitle: 'Mentors provide the perfect oppourtunity to grow your\nnetwork at a time where it’s most needed.',
        img_urls: [require('../../assets/images/no-connection.png')],
        img_alts: ['programmer worried with no network'],
    },
    {
        id: 'badass_mentors',
        title: 'Our badass mentors',
        subtitle: 'Mentors range from teachers, startup founders and\nex/current employees of amazing companies.',
        img_urls: [require('../../assets/images/mentor-companies.png')],
        img_alts: ['companies our mentors are associated with'],
    },
    {
        id: 'our_story',
        title: 'Our Story',
        subtitle: `CodeBetter was born out of necessity. A few close friends suddenly lost
        their jobs due to external circumstances and banded together to create a community
        to keep each other's skills and spirits up.`,
        img_urls: [
            require('../../assets/images/people/im1.png'),
            require('../../assets/images/people/im2.png'),
            require('../../assets/images/people/im3.png'),
            require('../../assets/images/people/im4.png'),
            require('../../assets/images/people/im5.png'),
        ],
        img_alts: ['people-img-1','people-img-2','people-img-3','people-img-4','people-img-5'],
        invert_text_align: true,
    },
    {
        id: 'sign_up',
        title: 'Sign up today for free!',
        subtitle: 'Any type of programmer or projects are welcome.',
        img_urls: [],
        img_alts: [],
    },
];