import React from 'react';

import './styles.scss';

function LandingPageSection({ id, title, subtitle, background_color, classnames, img_alts = [], img_urls = [], text_align_left = true, children = null }) {
    const LandingPageHTML = {
        titleContent: title && subtitle ?
            (
                <div className="section-col">
                    <div className="section-msg">
                        <h1>{title}</h1>
                        <h4>{subtitle}</h4>
                    </div>
                    {children}
                </div>
            ) :
            null,
        imgContent: img_urls.length > 0 ? (
            <div className="section-col">
                <div className="section-img-container">
                    {
                        img_urls.map((img_url, idx) => <img key={idx} src={img_url} alt={img_alts[idx]}></img>)
                    }
                </div>
            </div>
        ) :
            null
    }

    return <>
        <section id={id} className={classnames} style={{ backgroundColor: `${background_color}` }}>
            <div className="section-content">
                {
                    text_align_left ?
                        <>
                            {LandingPageHTML.titleContent}
                            {LandingPageHTML.imgContent}
                        </>
                        :
                        <>
                            {LandingPageHTML.imgContent}
                            {LandingPageHTML.titleContent}
                        </>
                }
            </div>
        </section>
    </>
}

export default LandingPageSection;