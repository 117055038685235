import React from 'react';
import { Helmet } from 'react-helmet';

import SocialImage from '../../assets/images/social-image.png';

function HelmetHead() {
    return <Helmet>
        <meta charSet="utf-8" />
        <title>CodeBetter</title>

        <meta name="description" content="Code better with the help of a mentor. Sign up now to share your talent, expertise and projects with the world!" />

        {/* Google / Search Engine Tags */}
        <meta itemprop="name" content="CodeBetter" />
        <meta itemprop="description" content="Code better with the help of a mentor. Sign up now to share your talent, expertise and projects with the world!" />
        <meta itemprop="image" content={SocialImage} />

        {/* Facebook Meta Tags */}
        <meta property="og:url" content="https://wndaiga.github.io/codebetter" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="CodeBetter" />
        <meta property="og:description" content="Code better with the help of a mentor. Sign up now to share your talent, expertise and projects with the world!" />
        <meta property="og:image" content={SocialImage} />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="CodeBetter" />
        <meta name="twitter:description" content="Code better with the help of a mentor. Sign up now to share your talent, expertise and projects with the world!" />
        <meta name="twitter:image" content={SocialImage} />
    </Helmet>
}

export default HelmetHead;