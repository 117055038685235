import React from 'react';

import './styles.scss';

function Footer(props) {
    return <>
        <div className="footer">
            <p>CodeBetter</p>
            <div>
                <button className="link-button">T&Cs</button>
                <button className="link-button">Privacy Policy</button>
            </div>
        </div>
    </>
}

export default Footer;