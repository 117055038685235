import React from 'react';
import Button from 'react-bootstrap/Button';

import content from './content';
import LandingPageSection from '../../components/LandingPageSection';

import './styles.scss';

function LandingPage(props) {
    const contentChildrenMap = {
        header: (
            <div className="section-btns">
                <Button variant="primary" size="lg">Sign up today</Button>
                <Button variant="outline-primary" size="lg">Be a Mentor</Button>
            </div>
        ),
        get sign_up() { return this.header },
    };

    const formattedContent = content.map((content, idx) => ({
        text_align_left: (function () {
            let align_left = idx % 2 ? false : true;
            if ('invert_text_align' in content) {
                align_left = !align_left
            }
            return align_left;
        }()),
        background_color: idx % 2 ? '#EAEAEA' : '#F9FBFA',
        children: contentChildrenMap[content.id],
        ...content
    }));

    return <>{
        formattedContent.map((content, idx) =>
            <LandingPageSection
                key={idx}
                id={content.id}
                title={content.title}
                subtitle={content.subtitle}
                img_urls={content.img_urls}
                img_alts={content.img_alts}
                text_align_left={content.text_align_left}
                background_color={content.background_color}
                children={content.children}></LandingPageSection>
        )
    }</>
}

export default LandingPage;